//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { selectPerson } from '@/mixins/index'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
export default {
  components: {
    OpenData,
    selectPersonDialog,
  },
  mixins: [selectPerson],
  data() {
    return {
      visible: false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      nodeMess: {},
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      personList: [],
      all_list: [],
    }
  },
  watch: {
    personList(val) {
      if (val && val.length) {
        this.form.setFieldsValue({
          person: JSON.stringify(val),
        })
      } else {
        this.form.setFieldsValue({
          person: '',
        })
      }
    },
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          this.$emit('selectPersons', {
            nodeMess: this.nodeMess,
            values,
          })
          this.nodeMess = {}
          this.form.resetFields()
          this.visible = false
        }
      })
    },
    open(nodeMess) {
      this.nodeMess = nodeMess
      const { el, allList } = nodeMess
      this.all_list = allList || []
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: el.name,
          countersign: el.countersign || 1,
        })
        this.personList = el.val
      })
    },
    handleOk() {
      this.handleSubmit()
    },
    handleCancel() {
      this.nodeMess = {}
      this.form.resetFields()
      this.visible = false
    },
  },
}
